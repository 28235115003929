import React from 'react';
import Verify from 'gatsby-theme-q3/src/pages/verify';
import FormContainer from '../../components/FormContainer';
import HydrationFix from '../../components/HydrationFix';

const CustomVerify = (props) => (
  <HydrationFix>
    <FormContainer>
      <Verify {...props} />
    </FormContainer>
  </HydrationFix>
);

export default CustomVerify;
